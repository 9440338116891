import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import KalFirm from '../../components/ModalOpen/KalFirm'
import config from '../../../config'
import Layout from '../../components/Layout'

const ZamowienieKf = () => {
  return (
  <Layout>
  <Helmet
    htmlAttributes={{
      lang: `en-en`,
    }}
    meta={[
      {
        name: `viewport`,
        content: `width=device-width, initial-scale=1`,
      },
      {
        name: `msapplication-TileColor`,
        content: config.themeColor,
      },
      {
        name: `theme-color`,
        content: config.themeColor,
      },
    ]}
    link={[
      {
        rel: `apple-touch-icon`,
        href: `/icons/apple-touch-icon.png`,
        sizes: `180x180`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-32x32.png`,
        sizes: `32x32`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-16x16.png`,
        sizes: `16x16`,
      },
      {
        rel: `mask-icon`,
        href: `/icons/safari-pinned-tab.svg`,
        color: config.themeColor,
      },
      {
        rel: `preconnect`,
        href: `https://fonts.gstatic.com`,
      },
      {
        rel: `stylesheet`,
        href: `https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap`,
      },
    ]}
  >
  <title>Order company calendars - free online quote - Art Open</title>
  <meta name="description" content="Order corporate calendars - answer the questions to receive a free quote for our book calendars within 24 hours. Check it out!" />
  <script type='application/ld+json'>
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://artopen.co/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Order corporate calendars",
    }]
  })}
  </script>
  </Helmet>
  <div style={{minHeight:'100%',backgroundImage:'url(/img/tlo3.svg)',backgroundSize:'cover',paddingLeft:'5%',backgroundAttachment:'fixed',paddingRight:'5%',paddingTop:'5%',zIndex:'20000',fontFamily:'Poppins, sans-serif !important'}}>
  <nav className="breadcrumbs-nav" style={{marginBottom:'25px'}}>
  <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>Order corporate calendars</b></span>
  </nav>
  <div  style={{textAlign:'center',marginTop:'auto'}}>
  <Link to="/">
    <img width="200px" src='/img/ArtOpen.svg' alt="ArtOpen advertising studio" />
  </Link>
    <br />
  </div>
  <br />
  <h1 className="stays-dark" style={{textAlign:'center'}}>Feel free to answer the form's questions and get a <b>free service quote within 24 hours. </b></h1>
  <h2 className="stays-dark" style={{textAlign:'center',fontSize:'12px'}}>Sending an inquiry is not obligatory - it will be used to provide a free quote.</h2>
  <br /><br />
  <div className='boxshadow modalitem' id='Kalendarzefirmowe' style={{ display:'block'}}>
  <KalFirm />
  </div>

  <div className='boxshadow modalitem'  id='KalendarzefirmoweX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
  <div className='hero-body has-text-centered'>
  <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Corporate calendars" src='/img/check.svg'/>
    <p className='title' style={{color:'white'}}>
      The message has been sent.
    </p>
  </div>
  </div>
  <div style={{textAlign:'center',paddingBottom:'50px'}}>
  </div>
  </div>
  </Layout>
)
}

export default ZamowienieKf
